<template>
    <master-panel>
        <template v-slot:body>
            <loading-spinner v-if="loading" line height=""/>
            <v-row v-else>
                <v-col cols="12">
                    <title-container :title="clientName"/>
                </v-col>
                <v-col cols="12" v-for="(response, i) in responses" :key="i" class="pb-0">
                    <div class="title-response">
                        {{ i + 1 }}. {{ response.question.text }}
                    </div>
                    <div class="text-response" v-html="formattedResponse(response.response)" />
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-row>
                <v-col cols="6" align="right" class="pa-1 pl-3">
                    <v-btn block color="primary" class="normal-btn" small depressed @click="goBack">
                        Volver
                    </v-btn>
                </v-col>
                <v-col cols="6" class="pa-1 pr-3">
                    <v-btn block color="primary" class="normal-btn" small depressed @click="goHome">
                        Dashboard
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </master-panel>
</template>

<script>
import MasterPanel from "@/components/layouts/MasterPanel";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import httpService from "@/services/httpService";
import TitleContainer from "@/components/elements/TitleContainer";
export default {
    name: "ClientQuestionnaire",
    components: {TitleContainer, LoadingSpinner, MasterPanel},
    data() {
        return {
            loading: false,
            client: null,
            responses: []
        }
    },
    computed: {
        clientId(vm = this) {
            return vm.$route.params.id || null
        },
        clientName(vm = this) {
            return vm.client?.name || ''
        }
    },
    async mounted() {
        const vm = this
        await vm.loadClient()
    },
    methods: {
        async loadClient() {
            const vm = this
            vm.loading = true

            let response = await httpService.get(`client/${vm.clientId}/responses`)
            // console.log('response', response)
            vm.client = response.data.client
            vm.responses = response.data.responses

            vm.loading = false
        },
        formattedResponse(response) {
            return response.replace(/\n/g, "<br>")
        }
    }
}
</script>

<style scoped>

.title-response {
    font-weight: bold;
    font-size: 15px;
}

.text-response {
    font-size: 13px;
    border: 1px solid #707070;
    border-radius: 12px;
    padding: 4px 12px;
}

</style>